
export default {
    addNew: {
        min: "최소 금액",
        failed: "종료됨",
        success: "결제 완료",
        waiting: "진행 중",
        submit: "가입",
        day: "일",
        time: "시간",
        foot: "재테크",
        tab0: "재테크 항목",
        tab1: "재테크 주문",
    },
    common: {
        recharge_price: "화폐의 가치",
        recharge_number: "충전 수량",
        winner_money: "이익",
        trade_number: "주문 번호",
        balance2: "계좌 자금",
        balance1: "현물 자산 가치",
        withdraw_real: "입금 수량",
        withdraw_money: "현금 인출 수량",
        withdraw_tips: "CEEX 거래소 현금 인출 수수료는 0.2%",
        withdraw_fee: "수수료",
        notice1: "먼저 상가 계좌로 업그레이드하고, 상세한 내용은 고객센터에 연락하십시오.",
        trc_address2: "TRC20-USDT 출금 주소",
        trc_address: "인출 주소",
        footer_1: "홈",
        footer_2: "홀",
        footer_3: "주문",
        footer_4: "나의",
        verify_waiting: "보류 중",
        verify_success: "성공",
        verify_failed: "실패",
        app_name: "CEEX",
        currency_unit: "$",
        confirm: "인식",
        cancel: "취소",
        save: "(파일 등) 저장 (컴퓨팅)",
        no_data: "사용 가능한 데이터 없음",
        copy: "복제",
        copy_success: "복사 성공！",
        copy_error: "복제 실패",
        tip: "STH에 주목하세요.",
        to: "향해",
        search: "STH를 찾습니다.",
        language: "언어 선택",
        freezeMessage: "상사에게 문의하세요.",
        completeOrder: "이 주문을 완료하시겠습니까？",
        logout: "취소하시겠습니까？",
        logout_success: "종료 성공！",
        logout_filed: "종료 실패！",
        currency: "통화",
        deposit: "은행에 돈을 저축하세요",
        withdraw: "중단",
        invite: "가입 초대",
        balance: "잔액",
        deposit_successful: "입금 성공",
        withdraw_successful: "입금 성공 출금 성공",
        deposit_fail: "입금 실패",
        details: "자세한 정보",
        add: "증가",
        remind: "미리 알림",
        success: "성공 사례",
        description: "지침",
        submit: "제출(보고서 등)",
        no_record: "기록 없음",
        countryCode: "국가 코드",
        withdraw0: "신청서(양식 등)",
        withdraw1: "결정을 기다립니다.",
        withdraw2: "성공 사례",
        withdraw3: "실패(예: 실험)",
        orderNow: "지금 주문하기",
        noBalance: "사용 가능한 계정 잔액 부족 ！",
        needTo: "계정의 사용 가능한 잔액이 부족합니다! 충전해야 합니다. ",
        all: "전체",
        nodata: "사용 가능한 데이터 없음",
    },
	addnew: {
		notice: "안녕하세요, CEEX 플랫폼에 오신 것을 환영합니다!온라인 고객 서비스 시간은 오전 9시부터 24시까지입니다!",
    },
    account: {
        download: "앱 다운로드",
        forget: "비밀번호를 잊으셨나요？",
        now_login: "지금 로그인",
        no_account: "계정이 없습니다?",
        now_register: "지금 등록하기",
        welcome: "환영",
        email_register: "사서함 등록",
        phone_register: "휴대폰 등록",
        email_forget: "사서함 복구",
        phone_forget: "휴대폰 검색",
        cilck_obtain: "얻으려면 클릭",
        back_login: "카탈로그로 돌아가기",
    },
    hall: {
        sell: "판매 제안",
        get_amount: "금액",
        merchant_upgrade: "상인 업그레이드",
        ibuy: "구매하려고 합니다",
        isell: "판매하려고 합니다",
        selling_order: "공동 판매 주문",
        welfare_orders: "혜택 주문",
        merchant: "사업가	",
        unit_price: "가격",
        number: "수량",
        surplus: "남아있음",
        limit: "할당량",
        go_sell: "판매하기",
        name: "이름",
        balance: "잔액",
        profit: "혜택 받기",
        bank_account: "은행 계좌 번호",
        now_price: "현재 가격",
        optimal_price: "최적 가격",
        confirm_buy: "구매 확정",
        confirm_sell: "판매 확인",
    },
    home: {
        notice: "안녕하세요, CEEX 플랫폼에 오신 것을 환영합니다! 온라인 고객 서비스는 오전 9시부터 오후 24시까지 이용 가능합니다!",
        home_nav1: "초보자 안내서",
        home_nav2: "가입 초대",
        home_nav3: "팀 목록",
        home_nav4: "시스템 메시지",
        quick_trans: "빠른 거래",
        one_click: "원클릭 구매",
        ctc_trans: "C2C 거래",
        buy_sell: "구매 및 판매",
        online: "온라인 사용자",
        total_trans: "24시간 총 거래량",
        message: "시스템 뉴스",
        detail: "세부 정보",
    },
    assets: {
        recharge: "충전",
        withdraw: "출금",
        type: "유형학",
        address: "주소",
        tip: "자금의 안전을 위해 이체가 완료된 후 검토를 위해 이체 성공 스크린샷을 제출해 주세요.",
        bank: "ATM 카드",
    },
    my: {
        about: "플랫폼 소개",
        account_bind: "계정 바인딩",
        approved: "승인됨",
        account_detail: "계정 세부 정보",
        number: "수량",
        trans_success: "거래 성공",
        my_assets: "나의 자산",
        edit_nickname: "닉네임 수정",
        edit_psd: "비밀번호 변경",
        team: "팀 목록",
        invite: "등록 초대",
        logout: "로그아웃",
        my_invite_code: "내 초대 코드",
        invite_tip: "초대 코드를 복사하여 더 많은 친구를 초대하세요!",
        invite_btn: "프로모터 신청",
        team_member: "팀 구성원",
        add_month: "이번 달 신규 추가",
        invite2: "초대",
        personal: "개인 정보",
        avatar: "아바타",
        nickname: "닉네임",
        acount: "계정 번호",
        invite_code: "추천 코드",
    },
    order: {
        all: "전체",
        paid: "결제 완료",
        unpaid: "미결제",
        detail: "상세 정보",
        direction: "방향",
        buy: "지출",
        sell: "팔다",
        time: "시간",
        price: "가격",
        number: "수량",
        amount: "금액",
        income: "수입",
    },
    form: {
        place_old_psd: "기존 비밀번호를 입력하세요.",
        place_new_psd: "새 비밀번호를 입력하세요.",
        place_crmnew_psd: "새 비밀번호를 다시 입력하세요.",
        place_nickname: "닉네임을 입력하세요.",
        place_email_phone: "이메일/휴대폰 번호를 입력하세요.",
        place_login_psd: "로그인 비밀번호를 입력하세요.",
        place_email: "이메일 주소를 입력하세요.",
        place_phone: "휴대폰 번호를 입력하세요.",
        place_verify_code: "인증 코드를 입력하세요.",
        place_confirm_psd: "비밀번호를 확인해 주세요.",
        place_invite_code: "초대 코드를 입력하세요.",
        place_usdt_number: "USDT 금액을 입력하세요.",
        buy_price: "구매 가격",
        place_buy_price: "입찰 가격을 입력하세요.",
        buy_number: "구매한 수량",
        place_buy_number: "구매하고자 하는 수량을 입력하세요.",
        sell_price: "판매 가격",
        place_sell_price: "판매 가격을 입력하세요.",
        sell_number: "판매 수량",
        place_sell_number: "판매 수량을 입력하세요.",
        nationality: "국적",
        place_nationality: "국적을 선택해 주세요.",
        name: "이름",
        place_name: "이름을 입력하세요.",
        age: "나이",
        place_age: "나이를 입력하세요.",
        address: "주소",
        place_address: "주소를 입력하세요.",
        postal_code: "우편번호",
        place_postal_code: "우편번호를 입력하세요.",
        phone: "전화 번호",
        place_select: "선택하세요",
        bank_name: "은행 이름",
        place_bank_name: "은행 이름을 입력하세요.",
        bank_account: "은행 계좌 번호",
        place_bank_account: "은행 계좌 번호를 입력하세요.",
        id_card: "문서 사진",
        place_id_card: "사진을 업로드하세요.",
        place_number: "수량을 입력하세요.",
        upload_credentials: "인증서 업로드",
        place_upload_credentials: "증빙 자료를 업로드하세요.",
        place_psd: "비밀번호를 입력하세요.",
    },
    city: {
        albania: "알바니아",
        algeria: "알제리",
        argentina: "아르헨티나",
        armenia: "아르메니아",
        australia: "호주",
        pakistan: "파키스탄",
        austria: "오스트리아",
        bahrain: "바레인",
        belgium: "벨기에",
        bosnia_and_Herzegovina: "보스니아 헤르체고비나",
        brazil: "브라질",
        brunei: "브루나이",
        bulgaria: "불가리아",
        cambodia: "캄보디아",
        canada: "캐나다",
        cameroon: "카메룬",
        chile: "칠레",
        colombia: "콜롬비아",
        costa_Rica: "코스타리카",
        croatia: "크로아티아",
        cyprus: "키프로스",
        czech_Republic: "체코",
        denmark: "덴마크",
        dominican_Republic: "도미니카",
        egypt: "이집트",
        estonia: "에스토니아",
        ethiopia: "에티오피아",
        finland: "핀란드",
        france: "프랑스",
        georgia: "그루지야",
        germany: "독일",
        ghana: "가나",
        greece: "그리스",
        guyana: "가이아나",
        honduras: "온두라스",
        hong_Kong_China: "중국 홍콩",
        hungary: "헝가리",
        iceland: "아이슬란드",
        ireland: "아일랜드",
        italy: "이탈리아",
        india: "인도",
        indonesia: "인도네시아",
        israel: "이스라엘",
        iran: "이란",
        iraq: "이라크",
        japan: "일본",
        kazakstan: "카자흐스탄",
        kenya: "케냐",
        korea: "한국",
        kuwait: "쿠웨이트",
        kyrgyzstan: "키르기스스탄",
        laos: "라오스",
        Latvia: "라트비아",
        lithuania: "리투아니아",
        luxembourg: "룩셈부르크",
        macao_China: "중국 마카오",
        macedonia: "마케도니아",
        malaysia: "말레이시아",
        malta: "몰타",
        mexico: "멕시코",
        moldova: "몰도바",
        monaco: "모나코",
        mongolia: "몽골",
        montenegro: "몬테네그로",
        morocco: "모로코",
        myanmar: "미얀마",
        netherlands: "네덜란드",
        new_Zealand: "뉴질랜드",
        nepal: "네팔",
        nigeria: "나이지리아",
        norway: "노르웨이",
        oman: "오만",
        palestine: "팔레스타인",
        panama: "파나마",
        paraguay: "파라과이",
        peru: "페루",
        philippines: "필리핀",
        poland: "폴란드",
        portugal: "포르투갈",
        puerto_Rico: "푸에르토리코",
        qatar: "카타르",
        romania: "루마니아",
        russia: "러시아",
        republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
        rwanda: "르완다",
        saudi_Arabia: "사우디아라비아",
        serbia: "세르비아",
        singapore: "싱가포르",
        slovakia: "슬로바키아",
        slovenia: "슬로베니아",
        south_Africa: "남아프리카",
        spain: "스페인",
        sri_Lanka: "스리랑카",
        sweden: "스웨덴",
        switzerland: "스위스",
        taiwan_China: "대만",
        tajikistan: "타지키스탄",
        tanzania: "탄자니아",
        thailand: "태국",
        turkey: "터키",
        turkmenistan: "투르크메니스탄",
        ukraine: "영국",
        united_Arab_Emirates: "미국",
        united_Kingdom: "우즈베키스탄",
        united_States: "베네수엘라",
        uzbekistan: "베트남",
        venezuela: "아프가니스탄",
        vietnam: "베트남",
        afghanistan: "아프가니스탄",
        angola: "앙골라",
        azerbaijan: "아제르바이잔",
        bangladesh: "방글라데시",
        belarus: "벨라루스",
        belize: "벨리즈",
        benin: "베냉",
        bhutan: "부탄",
        bolivia: "볼리비아",
        botswana: "보츠와나",
        british_Virgin_Islands: "영국령 버진 아일랜드",
        burkina_Faso: "부르키나파소",
        burundi: "부룬디",
        cape_Verde: "카보베르데",
        cayman_Islands: "케이맨 제도",
        central_African_Republic: "중앙아프리카",
        chad: "차드",
        comoros: "코모로",
        the_Republic_of_the_Congo: "콩고 (부)",
        democratic_Republic_of_the_Congo: "콩고 (김)",
        djibouti: "지부티",
        ecuador: "에콰도르",
        el_Salvador: "엘살바도르",
        equatorial_Guinea: "적도 기니",
        eritrea: "에리트레아",
        fiji: "피지",
        gabon: "가봉",
        gambia: "감비아",
        greenland: "그린란드",
        guatemala: "과테말라",
        guinea: "기니",
        haiti: "아이티",
        isle_of_Man: "맨 섬",
        cote_d_Ivoire: "코트디부아르",
        jamaica: "자메이카",
        jordan: "요르단",
        lebanon: "레바논",
        lesotho: "레소토",
        liberia: "라이베리아",
        libya: "리비아",
        madagascar: "마다가스카르",
        malawi: "말라위",
        maldives: "몰디브",
        mali: "말리",
        mauritania: "모리타니",
        mauritius: "모리셔스",
        mozambique: "모잠비크",
        namibia: "나미비아",
        nicaragua: "니카라과",
        republic_of_Niger: "니제르",
        north_Korea: "조선",
        reunion: "레위니옹",
        san_Marino: "산마리노",
        senegal: "세네갈",
        sierra_Leone: "시에라리온",
        somalia: "소말리아",
        sudan: "수단",
        suriname: "수리남",
        eswatini: "스와질란드",
        syria: "시리아",
        togo: "토고",
        tonga: "통가",
        tunisia: "튀니지",
        united_States_Virgin_Islands: "미국령 버진 아일랜드",
        uganda: "우간다",
        uruguay: "우루과이",
        vatican: "바티칸",
        yemen: "예멘",
        yugoslavia: "유고슬라비아",
        zambia: "잠비아",
        zimbabwe: "짐바브웨",
        china: "중국",
    }
}


